import React, { useState } from "react";
import styled from "styled-components";

export default function PasswordInput({ hasError, handle }) {
  const [inputValue, setInputValue] = useState("");

  const [reveal, setReveal] = useState(false);

  function showPassword() {
    setReveal(!reveal);
  }

  return (
    <Container hasError={hasError}>
      <TextInput
        type={reveal ? "text" : "password"}
        value={inputValue}
        placeholder={"Password"}
        onChange={(event) => {
          setInputValue(event.target.value);
          handle(event.target.value);
        }}
      />
      <Icon>
        <img
          src="/images/icons/eye.svg"
          width="20px"
          height="20px"
          alt="show"
          onClick={() => {
            showPassword();
          }}
        />
      </Icon>
    </Container>
  );
}

const Container = styled.div`
  height: 50px;
  display: grid;
  min-width: 350px;
  grid-template-columns: 10fr 1fr;
  overflow: hidden;

  align-items: center;
  background: ${(props) =>
    props.hasError ? "rgba(255, 113, 151, 0.1)" : "#ffffff"};
  border: 1px solid rgba(177, 169, 185, 0.3);
  box-sizing: border-box;
  border-radius: 35px;

  &:hover {
    box-shadow: 2px 2px 3px rgba(177, 169, 185, 0.2);
  }
`;

const TextInput = styled.input`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #73667d;

  font-size: inherit;
  height: 100%;
  border-radius: 35px 0 0 35px;
  padding-left: 15px;
  border: 0;
  /* Change the white to any color ;) */

  &:focus {
    outline: none;
    background-color: transparent;
  }

  ::-webkit-input-placeholder {
    color: rgba(177, 169, 185, 0.5);
  }
  :-ms-input-placeholder {
    color: rgba(177, 169, 185, 0.5);
  }
`;

const Icon = styled.div`
  justify-self: center;
  padding-right: 5px;
  filter: opacity(40%);
  cursor: pointer;
  :hover {
    filter: brightness(100%);
  }
`;
