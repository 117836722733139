import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import DaysFrom from "./login/Counter";
import DaysTo from "./login/DaysTo";
import DaysApart from "./login/DaysApart";
import LoginBox from "./login/LoginBox";

function LogIn() {
  return (
    <ContentWrapper>
      <DaysFrom />
      <LoginBox />
    </ContentWrapper>
  );
}

export default LogIn;

const ContentWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 30px;
  @media (min-width: 1300px) {
    gap: 50px;
  }
`;
